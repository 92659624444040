.workshop {
	$block: &;

	touch-action: manipulation !important;
	touch-action: pan-x !important;

	transition-property: filter;

	@include breakpoint(map-get($breakpoints, tablet)) {
		@at-root .gallery-active #{$block} {
			filter: blur(10px) contrast(75%) brightness(75%);
			transition-timing-function: $transition-ease-in;
		}
	}

	[data-hook~="background-blur"] {
		transition-property: filter;
		&.is-blurred {
			filter: blur(10px) contrast(90%);
			transition-timing-function: $transition-ease-in;
		}
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: block;
		object-fit: cover;
		object-position: 30% 50%;

		@include breakpoint(map-get($breakpoints, tablet)) {
			object-position: 50% 50%;
		}
	}

	&__subview {
		&, & > * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		& > * {
			touch-action: manipulation !important;
			touch-action: pan-y !important;
		}
	}

	&__nav {
		pointer-events: none;
		@include grid-container;
	}

	&__menu {
		margin: 0;
		margin-top: 3em;
		padding: 0;

		@include span(1 last);
		@include pre(5);
		@include grid-breakpoint(phablet) {
			@include span(1 last);
			@include pre(5);
		}
		@include grid-breakpoint(tablet) {
			@include span(2 last);
			@include pre(8);
		}
		@include grid-breakpoint(desktop) {
			@include span(2 last);
			@include pre(10);
		}

		list-style-type: none;

		li {
			display: block;
			margin-right: -8px;
			direction: rtl;

			a {
				display: block;
				padding: {
					right: 24px;
					top: 0.5em;
					bottom: 0.5em;
				}

				font-size: 0.625em;
				font-weight: normal;
				text-align: right;
				text-transform: uppercase;
				letter-spacing: 0.05em;

				text-decoration: none;
				color: #a9a9a7;

				overflow: hidden;
				pointer-events: auto;

				width: 24px;

				&::after {
					position: absolute;
					top: 50%;
					right: 8px;
					content: '';
					width: 8px;
					height: 8px;
					margin-top: -4px;
					border: 2px solid #808080;
					mix-blend-mode: screen;
				}

				@include breakpoint(map-get($breakpoints, tablet)) {
					width: auto;
					@include breakpoint(orientation portrait) {
						padding: {
							top: 1.5em;
							bottom: 1.5em;
						}
					}
				}

				@include breakpoint(map-get($breakpoints, desktop)) {
					@include breakpoint(orientation portrait) {
						padding: {
							top: 0.5em;
							bottom: 0.5em;
						}
					}
				}

			}

			&:hover {
				a {
					color: white;

					&::after {
						border-color: white;
					}
				}
			}

			&.is-active {
				a {
					color: white;

					&::after {
						border-color: $link-color;
					}
				}
			}
		}
	}

	&__sectionContainer {
		$span: span(4);
		$container: container();
		$padding: calc(calc(100% - calc(#{$span/100%} * #{$container})) / 2);
		position: absolute;
		top: 10vh;
		right: $padding;
		bottom: 15vh;
		left: $padding;

		@include breakpoint(orientation landscape) {
			top: 15vh;
			bottom: 20vh;
		}

		@include grid-breakpoint(phablet) {
			$span: span(4);
			$container: container();
			$width: $span/100% * $container;
			$padding: calc(calc(100% - #{$width}) / 2);
			right: $padding;
			left: $padding;
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		> section {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: none;
			touch-action: manipulation;
			touch-action: pan-y;

			&.is-active {
				display: block;
			}

			&:not(:first-child) {
				overflow-y: auto;
				overflow-x: visible;
			}
		}
	}

	&__titleWrapper {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		* {
			pointer-events: auto;
		}
	}

	&__subTitleWrapper {
		margin: 0 -50%;
		padding: 2.5em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		top: -7%;

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding: 6em;
			background: {
				repeat: no-repeat;
				size: contain;
				position: center center;
				image: radial-gradient(closest-side, hsla(0, 0%, 0%, 0.35), transparent);
			}
		}
	}

	&__title {
		$element: &;

		top: -7%;

		margin: 0 -50%;
		text-align: center;
		text-transform: uppercase;
		font-weight: normal;
		font-family: $aloveofthunder;
		line-height: 0.9;

		color: #808080;
		mix-blend-mode: screen;

		@at-root .backgroundcliptext & {
			background: url(/images/text-texture-tile.png);
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
		}

		@at-root .no-backgroundcliptext & {
			opacity: 0.75;
			mask: {
				image: url(/images/noise-tile.png);
				repeat: repeat;
				size: 100px;
			}
		}

		margin-top: 0.5em;
		max-width: 8em;
		font-size: 25vw;

		@include breakpoint(map-get($breakpoints, phablet)) {
			font-size: 7em;
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			margin-top: 0;
		}

		@include breakpoint(map-get($breakpoints, desktop)) {
			font-size: 10em;
		}

		@at-root .work #{$element} {
			@include breakpoint(max-width map-get($breakpoints, phablet) - 1px) {
				font-size: 32vw;
			}
		}
	}

	&__subTitle {
		color: $link-color;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $dooodleista;
		font-size: 2.5em;
		letter-spacing: 0.05em;
		line-height: 0.9;
		max-width: 4em;

		margin: 0 -50%;
		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background: {
			repeat: no-repeat;
			size: contain;
			position: center center;
			image: radial-gradient(closest-side, hsla(0, 0%, 0%, 0.35), transparent);
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			max-width: none;
		}
	}

	&__subSubTitle {
		margin: 1em auto;
		max-width: 20em;
		text-transform: uppercase;
		text-align: center;
		line-height: 1.4;
		letter-spacing: 0.1em;
		font-weight: bold;
		font-size: 0.75em;

		background: {
			repeat: no-repeat;
			size: contain;
			position: center center;
			image: radial-gradient(closest-side, hsla(0, 0%, 0%, 0.35), transparent);
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			font-size: 0.8em;
			background-image: none;
		}
	}

	&__heading {
		$element: &;

		$width: 30em;
		font-family: $dooodleista;
		color: white;
		line-height: 1;
		font-weight: bold;
		font-size: 2.25em;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.05em;
		margin: {
			left: -$width/2;
			right: -$width/2;
		}
		position: absolute;
		left: 50%;
		width: $width;

		top: 17vh;

		@include breakpoint(map-get($breakpoints, tablet)) {
			top: 22vh;
		}
	}

	&__gridContainer {
		@each $size in tablet, desktop {
			@include breakpoint(map-get($breakpoints, $size)) {
				@include with-layout(map-get($grids, $size)) {
					@include container;
				}
			}
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding-top: 15vh;
		}
	}

	&__row {
		@include break();
	}

	&__column {
		&:first-child {
			@include grid-breakpoint(tablet) {
				@include pre(1)
				@include span(4);
				padding-right: 1em;
				text-align: right;
			}
			@include grid-breakpoint(desktop) {
				@include pre(2);
				@include span(3);
				padding-right: 0;
			}
		}
		&:last-child {
			@include grid-breakpoint(tablet) {
				@include span(4);
				padding-left: 1em;
				text-align: left;
			}
			@include grid-breakpoint(desktop) {
				@include pre(2);
				@include span(3);
				padding-left: 0;
			}
		}
	}

	&__figure {
		margin: 1em 0;

		img, video {
			border: 0.4em solid black;
			width: 100%;
			height: auto;
			display: block;
			user-select: none;
			-webkit-user-drag: none;
		}
		video {
			object-fit: cover;
			position: static;
		}

		&Frame {
			width: 100%;

			> img {
				position: absolute;
				top: 0;
				left: 0;
				transition-property: opacity;
				opacity: 0;
				pointer-events: none;

				&:first-child {
					position: relative;
				}

				&.is-active {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}

		figcaption {
			font-style: italic;
			font-size: 0.7em;
			letter-spacing: 0.065em;
			margin: {
				top: 0.3em;
				right: 0;
				bottom: 0.5em;
				left: 0;
			}

			line-height: 1.4;
		}

		&Play {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 4em;
			margin-left: -2em;
			margin-top: -3em;
			cursor: pointer;
			filter: drop-shadow(0 0 3px hsla(0, 0%, 0%, 0.5));

			* {
				fill: none;
				stroke: white;
				stroke-width: 3px;
			}

			&:hover * {
				stroke: $link-color;
			}
		}
	}
	&__row > &__figure {
		@include grid-breakpoint(tablet) {
			@include pre(1)
			@include span(8);
			text-align: left;
		}
		@include grid-breakpoint(desktop) {
			@include pre(2);
			@include span(8);
		}
	}

	&__cta {
		margin: 0;
		padding: 0;
		text-align: center;
		list-style-type: none;
		@include grid-breakpoint(tablet) {
			@include pre(2);
			@include span(6);
			display: flex;
			justify-content: space-around;
		}
		@include grid-breakpoint(desktop) {
			@include pre(3);
			@include span(6);
		}

		> li {
			color: $link-color;
			line-height: 1;
			display: inline-block;
			font-size: 0.65em;
			font-weight: bold;
			pointer-events: auto;
			border-bottom: 1px solid $link-color;
			margin: {
				top: 0.5em;
				right: 2em;
				bottom: 0.5em;
				left: 2em;
			}

			@include breakpoint(map-get($breakpoints, tablet)) {
				float: none;
				margin: {
					top: 1em;
					right: auto;
					bottom: 1.9em;
					left: auto;
				}
			}

			a {
				color: white;
				display: inline-block;
				line-height: 1;
				text-decoration: none;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				padding-bottom: 0.1em;

				&:hover, &:focus {
					color: $link-color;
				}
			}
		}
	}
}
