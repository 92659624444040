.clients {

	/* For responsive section navigation */
	@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
		[data-hook~="section-container"] > section {
			display: block;
			pointer-events: none;
			overflow: visible;
		}
		[data-hook~="m-section"] {
			display: none;
			&.is-active {
				pointer-events: auto;
				display: block;
			}
		}
	}

	&__client {
		width: 100%;
		top: 26vh;
		cursor: default;

		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			position: absolute;
		}

		@include grid-breakpoint(tablet) {
			top: 27vh;
			@include span(2);
			&:first-child {
				@include pre(2);
			}
		}
		@include grid-breakpoint(desktop) {
			@include span(2);
			&:first-child {
				@include pre(3);
			}
		}

		&LogoContainer {
			height: 10vh;
			width: 100%;
			padding: {
				left: 1em;
				right: 1em;
			}
			display: flex;
			align-items: center;
		}
		&Logo {
			display: block;
			width: 100%;
			height: auto;
			max-height: 100%;
			max-width: 40vw;
			margin: 0 auto;
			object-fit: contain;
		}
		&Desc {
			font-size: 0.6em;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			font-weight: bold;
			text-align: center;
			margin: {
				top: 2em;
				right: auto;
				bottom: 0;
				left: auto;
			}
			max-width: 20em;

			@include breakpoint(map-get($breakpoints, tablet)) {
				max-width: auto;
			}
		}
	}
}
