.sectionNav {
	width: 100%;
	height: 100%;

	pointer-events: none;
	text-align: center;

	&__prevNext {
		list-style-type: none;
		margin: 0;
		padding: 0;
		top: 50vh;
		margin-top: -20px;

		@include grid-container;
	}

	&__prev,
	&__next {
		height: 30px;
		cursor: pointer;
		pointer-events: auto;
		filter: drop-shadow(0 0 3px hsla(0, 0%, 0%, 0.5));

		/* Disable selection, otherwise fast clicking will select some
		   contents */
		user-select: none;

		@include breakpoint(map-get($breakpoints, tablet)) {
			height: 40px;
		}

		svg {
			overflow: visible;
			position: absolute;
			top: 0;
			height: 100%;
			width: auto;
		}
	}
	&__prev {
		@include span(1 first);
		@include grid-breakpoint(phablet) {
			@include span(1 first);
		}
		@include grid-breakpoint(tablet) {
			@include span(1 first);
		}
		@include grid-breakpoint(desktop) {
			@include span(1 first);
		}

		svg {
			left: 0;
		}
	}
	&__next {
		@include span(1 last);
		@include pre(4);
		@include grid-breakpoint(phablet) {
			@include span(1 last);
			@include pre(4);
		}
		@include grid-breakpoint(tablet) {
			@include span(1 last);
			@include pre(8);
		}
		@include grid-breakpoint(desktop) {
			@include span(1 last);
			@include pre(10);
		}

		svg {
			right: 0;
		}
	}

	&__index {
		$element: &;

		$thickness: 0.125em;
		$height: $thickness + 1em;

		position: absolute;
		list-style-type: none;
		margin: 0;
		padding: 0;
		left: 0;
		width: 100%;
		text-align: center;
		display: inline-block;
		line-height: $height;

		transition-property: bottom;
		transition-timing-function: $transition-ease-in;

		bottom: 5vh;
		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			@include breakpoint(orientation landscape) {
				bottom: 7vh;
			}
		}
		@at-root .section-first #{$element} {
			bottom: 33vh;
		}
		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			@include breakpoint(orientation landscape) {
				@at-root .section-first #{$element} {
					bottom: 15vh;
				}
			}
		}

		&Item {
			cursor: pointer;
			pointer-events: auto;
			display: inline-block;
			line-height: $height;
			width: 20px;
			height: $height;
			filter: drop-shadow(0 0 3px hsla(0, 0%, 0%, 0.5));

			@include breakpoint(map-get($breakpoints, tablet)) {
				width: 30px;
			}

			span {
				cursor: pointer;
				pointer-events: auto;
				position: absolute;
				top: 50%;
				margin-top: -$thickness/2;
				left: 0.25em;
				right: 0.25em;
				height: $thickness;
				background-color: white;
			}

			&.is-active {
				span {
					background-color: $link-color;
				}
			}
		}
	}

	&__hint {
		$element: &;

		position: absolute;
		text-align: center;
		left: 0;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.625em;
		letter-spacing: 0.05em;
		opacity: 0.75;

		transition-property: bottom;
		transition-timing-function: $transition-ease-in;

		bottom: 2vh;
		@at-root .section-first #{$element} {
			bottom: 30vh;
		}
		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			@include breakpoint(orientation landscape) {
				@at-root .section-first #{$element} {
					bottom: 10vh;
				}
			}
		}

		display: none;
		&.is-active {
			display: block;
		}

		img {
			vertical-align: middle;
			margin: 0 0.25em;
		}

		&Desktop {
			display: none;
		}
		@include breakpoint(map-get($breakpoints, desktop)) {
			&Tablet {
				display: none;
			}
			&Desktop {
				display: block;
			}
		}
	}
}
