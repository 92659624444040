.vision {
	$block: &;

	overflow: hidden;

	touch-action: manipulation !important;
	touch-action: pan-x !important;

	&__navWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__nav {
		@include grid-container;

		&Index {
			margin: 0;
			margin-top: 3em;
			padding: 0;

			@include span(1 last);
			@include pre(5);
			@include grid-breakpoint(phablet) {
				@include span(1 last);
				@include pre(5);
			}
			@include grid-breakpoint(tablet) {
				@include span(1 last);
				@include pre(9);
			}
			@include grid-breakpoint(desktop) {
				@include span(1 last);
				@include pre(11);
			}

			list-style-type: none;

			li {
				display: block;
				margin-right: -8px;
				direction: rtl;

				span {
					display: block;
					padding: {
						right: 24px;
						top: 1em;
						bottom: 1em;
					}

					cursor: pointer;
					font-size: 0.625em;
					font-weight: normal;
					text-align: right;
					text-transform: uppercase;
					letter-spacing: 0.05em;

					text-decoration: none;
					color: #a9a9a7;

					overflow: hidden;
					pointer-events: auto;

					width: 24px;

					&::after {
						position: absolute;
						top: 50%;
						right: 8px;
						content: '';
						width: 8px;
						height: 8px;
						margin-top: -4px;
						border: 2px solid #808080;
						mix-blend-mode: screen;
					}

					@include breakpoint(map-get($breakpoints, tablet)) {
						width: auto;
						@include breakpoint(orientation portrait) {
							padding: {
								top: 2em;
								bottom: 2em;
							}
						}
					}

					@include breakpoint(map-get($breakpoints, desktop)) {
						@include breakpoint(orientation portrait) {
							padding: {
								top: 1em;
								bottom: 1em;
							}
						}
					}

				}

				&:hover {
					span {
						color: white;

						&::after {
							border-color: white;
						}
					}
				}

				&.is-active {
					span {
						color: white;

						&::after {
							border-color: $link-color;
						}
					}
				}
			}
		}
	}

	&__page {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		display: none;

		background: {
			repeat: no-repeat;
			position: center center;
			size: cover;
		}

		&.is-active {
			display: block;
		}

		&--1 {
			background-image: url(/images/vision/bulb-off.jpg);

			#{$block}__backgroundOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: {
					image: url(/images/vision/bulb-on.jpg);
					repeat: no-repeat;
					position: center center;
					size: cover;
				}
			}

			#{$block}__backgroundOverlay,
			#{$block}__title,
			#{$block}__subTitle {
				opacity: 0;
			}
		}

		&--2 {
			background-image: url(/images/vision/diver-background.jpg);

			#{$block}__backgroundOverlay {
				$ratio: 1181/1080;
				$height: 100vh;
				position: absolute;
				top: 0;
				left: 0;
				width: $ratio * $height;
				height: $height;

				> svg {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			#{$block}__backgroundOverlay {
				left: auto;
				right: 60%;
			}

			#{$block}__title,
			#{$block}__subTitle {
				opacity: 0;
			}
		}

		&--3 {
			background-image: url(/images/vision/plane-background.jpg);

			#{$block}__backgroundOverlay {
				$ratio: 1055/247;
				$height: 20vh;
				$width: $ratio * $height;
				position: absolute;
				left: 40%;
				top: 50vh;
				width: $width;
				height: $height;
				margin-left: -$width/2;

				> svg {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			#{$block}__title,
			#{$block}__subTitle {
				opacity: 0;
			}
		}

		&--4 {
			video {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;

				@include breakpoint(320px) {
					object-position: 35% 50%;
				}

				@include breakpoint(map-get($breakpoints, tablet)) {
					object-position: 50% 50%;
				}
			}

			#{$block}__title,
			#{$block}__subTitle {
				opacity: 0;
			}
		}
	}

	&__titleWrapper {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	&__title {
		margin: 0 -50%;
		text-align: center;
		text-transform: uppercase;
		font-weight: normal;
		font-family: $aloveofthunder;
		font-size: 2.8em;
		max-width: 8em;
		line-height: 0.9;

		color: #b7b7b7;
		mix-blend-mode: screen;

		@at-root .backgroundcliptext & {
			background: url(/images/text-texture-tile.png);
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
		}
		@at-root .backgroundcliptext #{$block}__page--2 & {
			mix-blend-mode: color-dodge;
		}
		@at-root .backgroundcliptext #{$block}__page--2 & {
			mix-blend-mode: color-dodge;
		}
		@at-root .backgroundcliptext #{$block}__page--3 & {
			mix-blend-mode: color-dodge;
		}

		@at-root .no-backgroundcliptext & {
			mask: {
				image: url(/images/noise-tile.png);
				repeat: repeat;
				size: 100px;
			}
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			color: #909090;
			font-size: 5em;
		}

		@at-root #{$block}__page--4 & {
			color: #909090;
		}
	}

	&__subTitle {
		margin: 0 -50%;
		padding: 2em;
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.1em;
		font-family: $dooodleista;
		font-size: 2em;
		line-height: 0.9;
		text-shadow: 0 0 4px hsla(0, 0%, 0%, 0.5);

		background: {
			repeat: no-repeat;
			size: contain;
			position: center center;
			image: radial-gradient(closest-side, hsla(0, 0%, 0%, 0.5), transparent);
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			font-size: 2.5em;
		}
	}
}
