.grain {
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: 200%;
	overflow: hidden;
	mix-blend-mode: difference;
	opacity: 0.5;
	pointer-events: none;
	z-index: 1;

	&--crisp {
		background-image: url(/images/grain-crisp.png);
	}

	&--blurred {
		background-image: url(/images/grain-blurred.png);
		transform: scale(1.5);
	}
}
