.loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $background-color;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	pointer-events: auto;

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 10em;
		max-width: 80%;
		height: 80%;
		overflow: hidden;

		@include breakpoint(min-height 37em) {
			height: 35em;
		}
	}

	&__top,
	&__bottom {
		position: absolute;
		left: 0;
		width: 100%;
		height: 50%;
		z-index: 1;
		background-color: $background-color;

		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	&__top {
		top: 0;

		&Vertical {
			$width: 2px;
			position: absolute;
			bottom: 0;
			height: 0;
			left: 50%;
			margin-left: -$width;
			width: $width;
			transform: rotate(180deg);

			svg path {
				fill: $link-color;
			}
		}
		&Horizontal {
			$height: 2px;
			position: absolute;
			left: 50%;
			width: 0;
			bottom: -$height;
			height: $height;

			svg path {
				stroke: $link-color;
				stroke-width: 2px;
			}
		}
	}
	&__bottom {
		bottom: 0;

		&Vertical {
			$width: 2px;
			position: absolute;
			top: 0;
			height: 0;
			left: 50%;
			margin-left: -$width;
			width: $width;

			svg path {
				fill: $link-color;
			}
		}
		&Horizontal {
			$height: 2px;
			position: absolute;
			left: 50%;
			width: 0;
			top: -$height;
			height: $height;

			svg path {
				stroke: $link-color;
				stroke-width: 2px;
			}
		}
	}

	&__content {
		display: none;
		padding: 1em;

		&.is-active {
			display: block;
		}
	}

	&__waitText {
		letter-spacing: 0.1em;
		line-height: 1;
		text-transform: uppercase;
		font-size: 0.6em;
		text-align: center;
		font-weight: bold;

		> span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__displayText {
		text-align: center;
		font-family: $dooodleista;
		font-weight: bold;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		font-size: 1.25em;

		@include breakpoint(min-height 37em) {
			font-size: 2.25em;
		}
	}

	&__mask {
		width: 5em;
		margin: 1em auto;

		> svg {
			width: 100%;
		}
	}
}
