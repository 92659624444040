/**
 * ITCSS structure:
 *
 * 1. Settings................Global variables, config switches.
 * 2. Tools...................Default mixins and functions.
 * 3. Generic.................Ground-zero styles (resets, box-sizing).
 * 4. Base....................Unclassed HTML elements (type selectors).
 * 5. Objects.................Cosmetic-free design patterns.
 * 6. Components..............Designed components, chunks of UI.
 * 7. Trumps..................Helpers and overrides.
 *
 */

/*
 * 1. Settings
 */

$background-color: #2b2521;
$text-color: white;
$link-color: #efb709;
$error-color: #fb4d00;
$transition-duration: 0.2s;
$transition-ease-in: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$transition-ease-out: cubic-bezier(0.390, 0.575, 0.565, 1.000);

@font-face {
	font-family: 'A Love of Thunder';
	src:
		url('/fonts/A_Love_of_Thunder.eot?') format('eot'),
		url('/fonts/A_Love_of_Thunder.woff2') format('woff2'),
		url('/fonts/A_Love_of_Thunder.woff') format('woff'),
		url('/fonts/A_Love_of_Thunder.ttf')  format('truetype');
}

@font-face {
	font-family: 'Dooodleista';
	font-weight: bold;
	font-style: normal;
	src:
		url('/fonts/Dooodleistabold.eot?') format('eot'),
		url('/fonts/Dooodleistabold.woff2') format('woff2'),
		url('/fonts/Dooodleistabold.woff') format('woff'),
		url('/fonts/Dooodleistabold.ttf')  format('truetype'),
		url('/fonts/Dooodleistabold.svg#Dooodleista-Bold') format('svg');
}

@font-face {
	font-family: 'Dooodleista Extra';
	font-weight: bold;
	font-style: normal;
	src:
		url('/fonts/Dooodleista-extra.eot?') format('eot'),
		url('/fonts/Dooodleista-extra.woff2') format('woff2'),
		url('/fonts/Dooodleista-extra.woff') format('woff'),
		url('/fonts/Dooodleista-extra.ttf')  format('truetype'),
		url('/fonts/Dooodleista-extra.svg#Dooodleista-extra') format('svg');
}

$dooodleista: 'Dooodleista Extra', 'Dooodleista', Arial, Helvetica, sans-serif;
$aloveofthunder: 'A Love of Thunder', Arial, Helvetica, sans-serif;


/*
 * If these are changed, then the code in `clients/app.js` also
 * needs to be adjusted.
 */
$grid-mobile-margin: 16px * 2;
$grids: (
	mobile: (
		container: calc(100% - #{$grid-mobile-margin * 2}),
		columns: 6,
	),
	phablet: (
		container: 560px,
		columns: 6,
	),
	tablet: (
		container: 720px,
		columns: 10,
	),
	desktop: (
		container: 960px,
		columns: 12,
	),
);

$breakpoints: ();
@each $size, $grid in $grids {
	@if $size != mobile {
		$breakpoints: map-merge($breakpoints,
			(#{$size}: map-get($grid, container) + $grid-mobile-margin*2));
	}
}

// Global susy grid settings
$susy: (
	global-box-sizing: border-box,
	container: map-get(map-get($grids, mobile), container),
	columns: map-get(map-get($grids, mobile), columns),
);

/*
 * 2. Tools
 */

@import 'susy';
@import 'breakpoint';

@mixin grid-breakpoint($size) {
	@include breakpoint(map-get($breakpoints, $size)) {
		@include with-layout(map-get($grids, $size)) {
			@content;
		}
	}
}

@mixin grid-container {
	@include container;
	@each $size, $width in $breakpoints {
		@include grid-breakpoint($size) {
			@include container;
		}
	}
}

%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
 * 3. Generic
 */

@import 'normalize';

*, *::before, *::after {
	box-sizing: border-box;
	position: relative;

	transition-property:
		color,
		border-color,
		fill,
		stroke;

	transition-duration: $transition-duration;
	transition-timing-function: $transition-ease-out;

	&:hover {
		transition-timing-function: $transition-ease-in;
	}
}

/*
 * 4. Base
 */

html,
body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

body {
	font-family: Arial, sans-serif;
	font-feature-settings: "liga", "kern";
	text-rendering: optimizeLegibility;

	color: $text-color;

	margin: 0;

	background-color: $background-color;
}

a {
	color: $link-color;
}

/*
 * 5. Objects
 */

/*
 * 6. Components
 */

body > [data-hook*="-container"] {
	&, & > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
[data-hook~="loader-container"],
[data-hook~="menu-container"],
[data-hook~="gallery-container"] {
	pointer-events: none;
}

@import 'components/loader';
@import 'components/grain';
@import 'components/menu';
@import 'components/home';
@import 'components/vision';
@import 'components/not-found';
@import 'components/workshop';
@import 'components/gallery';
@import 'components/section-nav';
@import 'components/services';
@import 'components/clients';
@import 'components/work';
@import 'components/contact';
@import 'components/origins';
@import 'components/form';

/*
 * 7. Trumps
 */

* {
	outline: none !important;
}
.has-outline {
	outline: {
		width: 1px !important;
		style: dashed !important;
		color: $link-color !important;
		offset: 0.5em !important;
	}
}
