.contact {

	&__heading {
		$element: &;

		font-family: $dooodleista;
		color: white;
		line-height: 1;
		font-weight: bold;
		font-size: 2.25em;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.05em;
		width: 100%;

		@at-root {
			.contact__map #{$element} {
				top: 17vh;

				@include breakpoint(map-get($breakpoints, tablet)) {
					top: 22vh;
				}
			}

			.contact__success #{$element},
			.contact__form #{$element} {
				position: relative;
				top: 0;
			}
		}
	}

	&__map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		isolation: isolate;

		> div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.gm-style > div:first-child {
			&::after,
			&::before {
				content: '';
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 2;
			}
			&::before {
				background-image: linear-gradient(to bottom,
					$background-color 0,
					$background-color 10%,
					transparent 50%);
			}
			&::after {
				background-image: linear-gradient(to top,
					$background-color 0,
					$background-color 10%,
					transparent 50%);
			}
		}

		&Infobox {
			background-color: $link-color;
			padding: 2em;
			width: 250px !important;
			overflow: unset !important;

			margin-left: -125px;
			margin-top: 20px;
			&::after {
				bottom: 100%;
				right: 50%;
				border: solid transparent;
				content: '';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: transparent;
				border-bottom-color: $link-color;
				border-width: 10px;
				margin-right: -10px;
			}

			@include breakpoint(map-get($breakpoints, tablet)) {
				margin-left: 40px;
				margin-top: -130px;
				&::after {
					right: 100%;
					top: 50%;
					bottom: auto;
					border-color: transparent;
					border-right-color: $link-color;
					margin-top: -10px;
					margin-right: auto;
				}
			}

			img {
				margin: {
					top: -1em !important;
					right: -1em !important;
					left: 0 !important;
					bottom: 0 !important;
				}
			}

			> p {
				margin: 1.5em 0;
				text-transform: uppercase;
				color: #222;
				line-height: 1.4;
				font-weight: bold;
				font-family: Arial, sans-serif !important;

				&:first-of-type {
					margin-top: 0;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}

			a {
				color: inherit;
				&:hover {
					color: white;
				}
			}

			ul {
				list-style-type: none;
				display: inline-block;
				margin: 0 auto;
				margin-top: 2em;
				padding: 0;
			}

			li {
				display: inline-block;
				width: 2em;
				margin-right: 1.5em;

				&:last-child {
					margin-right: 0;
				}

				svg * {
					fill: #222;
				}
				&:hover svg * {
					fill: white;
				}
			}
		}

		&:not(.is-blurred) + .workshop__sectionContainer {
			pointer-events: none;
		}

		&.is-blurred {
			pointer-events: none;
		}
	}

	&__contact {
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	&__form,
	&__success {
		overflow-x: hidden;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		touch-action: manipulation;
		touch-action: pan-y;
	}

	&__success {
		&Suggestion {
			color: $text-color;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-size: 0.8em;
			font-weight: bold;
			margin-bottom: 3em;

			@include breakpoint(map-get($breakpoints, tablet)) {
				text-align: center;
			}
		}

		&Option {
			margin-top: 3em;
			color: $link-color;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-size: 0.8em;
			font-weight: bold;
		}

		&Link {
			color: $link-color;
			line-height: 1;
			display: inline-block;
			font-size: 0.65em;
			font-weight: bold;
			pointer-events: auto;
			border-bottom: 1px solid $link-color;

			a {
				color: white;
				display: inline-block;
				line-height: 1;
				text-decoration: none;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				padding-bottom: 0.1em;

				&:hover, &:focus {
					color: $link-color;
				}
			}
		}
	}
}
