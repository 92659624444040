.form {
	$block: &;

	$border-width: 2px;
	$colors: (
		normal: $text-color,
		focus: $link-color,
		error: $error-color,
		success: $link-color,
		disabled: darken($link-color, 20%),
	);

	%is-required-label {
		&::before {
			content: '*';
			color: $link-color;
			margin-right: 0.1em;
		}
	}

	&__group {
		@extend %clearfix;
		margin: 1em 0;
		width: 100%;
	}

	&__label {
		$element: &;

		display: block;
		font-size: 0.8em;
		color: map-get($colors, normal);
		text-transform: uppercase;
		letter-spacing: 0.05em;
		margin-top: 2em;

		& + #{$block}__input,
		& + #{$block}__captcha {
			margin-top: 1em;
		}

		@at-root {
			#{$block}__group.is-required #{$element} {
				@extend %is-required-label;
			}
			#{$block}__group.has-success #{$element} {
				color: map-get($colors, success);
			}
			#{$block}__group.has-error #{$element} {
				color: map-get($colors, error);
			}
		}

		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			@media screen and (-webkit-min-device-pixel-ratio:0) {
				& {
					font-size: 1/0.8 * 1em;
				}
			}
		}
	}

	&__input {
		$element: &;

		border: {
			radius: 0;
			color: map-get($colors, normal);
			width: $border-width;
			style: solid;
		}
		color: map-get($colors, normal);
		width: 100%;
		padding: 1.25em;
		background-color: transparent;
		font-size: 0.7em;
		text-align: inherit;
		letter-spacing: 0.065em;

		&::placeholder {
			color: darken(map-get($colors, normal), 30%);
		}

		&:focus {
			border-color: map-get($colors, focus);
		}

		&:disabled[class][class][class] {
			border-color: map-get($colors, disabled);
			color: map-get($colors, disabled);
		}

		@at-root {
			#{$block}__group.has-success #{$element} {
				border-color: map-get($colors, success);
			}
			#{$block}__group.has-error #{$element} {
				border-color: map-get($colors, error);
			}
			input#{$element} {
				line-height: 1;
			}
			textarea#{$element} {
				line-height: 1.4;
				height: 24em;
				resize: vertical;
			}
		}

		@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
			@media screen and (-webkit-min-device-pixel-ratio:0) {
				& {
					font-size: 1em;
				}
			}
		}
	}

	&__radioGroup {
		$element: &;

		@extend %clearfix;

		margin: 1em 0;
		width: 100%;

		&Label {
			$element: &;

			display: block;
			font-size: 0.8em;
			color: map-get($colors, normal);
			text-transform: uppercase;
			letter-spacing: 0.05em;
			margin-top: 2em;
			margin-bottom: 1em;
			text-align: right;

			@at-root {
				#{$block}__radioGroup.is-required #{$element} {
					@extend %is-required-label;
				}
				#{$block}__radioGroup.has-success #{$element} {
					color: map-get($colors, success);
				}
				#{$block}__radioGroup.has-error #{$element} {
					color: map-get($colors, error);
				}
			}

			@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
				@media screen and (-webkit-min-device-pixel-ratio:0) {
					& {
						font-size: 1/0.8 * 1em;
					}
				}
			}
		}

		&Container {
			@include breakpoint(map-get($breakpoints, tablet)) {
				float: right;
			}
		}
	}

	&__radio {
		@extend %clearfix;

		text-align: right;
		margin-bottom: 0.5em;
		margin-left: 0.4em;

		@include breakpoint(map-get($breakpoints, tablet)) {
			float: left;
			margin-bottom: 0;
		}

		&Label {
			display: inline-block;
			font-size: 0.8em;
			color: map-get($colors, normal);
			text-transform: uppercase;
			letter-spacing: 0.05em;
			direction: rtl;
			text-align: right;
			margin-right: 0.5em;

			@include breakpoint(map-get($breakpoints, tablet)) {
				width: 4em;
				float: left;
			}

			@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
				@media screen and (-webkit-min-device-pixel-ratio:0) {
					& {
						font-size: 1/0.8 * 1em;
					}
				}
			}
		}

		&Container {
			display: inline-block;
			top: 0.3em;
			width: 1.3em;
			height: 1.3em;

			@include breakpoint(map-get($breakpoints, tablet)) {
				top: 0.2em;
				display: block;
				float: left;
			}
		}

		&Input {
			position: absolute;
			opacity: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		&Input + &Style {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: {
				radius: 0;
				color: map-get($colors, normal);
				width: $border-width;
				style: solid;
			}

			&::after {
				opacity: 0;
				content: '';
				background-color: map-get($colors, normal);
				position: absolute;
				top: $border-width * 2;
				right: $border-width * 2;
				bottom: $border-width * 2;
				left: $border-width * 2;
			}
		}
		&Input:focus + &Style {
			border-color: map-get($colors, focus);

			&::after {
				background-color: map-get($colors, focus);
			}
		}
		&Input:disabled + &Style {
			border-color: map-get($colors, disabled);

			&::after {
				background-color: map-get($colors, disabled);
			}
		}
		&Input:checked + &Style::after {
			opacity: 1;
		}
	}

	&__messageWrapper,
	&__message {
		display: none;
	}

	&__button {
		cursor: pointer;
		border: {
			radius: 0;
			color: map-get($colors, normal);
			width: $border-width;
			style: solid;
		}
		font-family: $dooodleista;
		font-weight: bold;
		font-size: 1.25em;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		line-height: 1;
		padding: {
			top: 0.5em;
			right: 0.5em;
			bottom: 0.4em;
			left: 0.5em;
		}
		color: map-get($colors, normal);
		background-color: transparent;

		&:hover,
		&:focus {
			border-color: map-get($colors, focus);
			color: map-get($colors, focus);
		}
		&:disabled {
			border-color: map-get($colors, disabled);
			color: map-get($colors, disabled);
		}

		&[type="submit"] {
			$element: &;

			float: right;
			margin-left: 1em;
			margin-bottom: 1em;

			&::after {
				content: '>';
				margin-left: 0.25em;
			}

			&:hover,
			&:focus {
				&::after {
					left: 0.05em;
				}
			}

			@at-root #{$block}.has-error #{$element} {
				border-color: map-get($colors, error);
				color: map-get($colors, error);

				&::after {
					left: 0.05em;
				}
			}
		}
	}


	&__captcha {
		float: right;
		margin-right: -6px;

		&Container {
			#{$block}__label {
				text-align: right;
			}
		}
	}

	&__finalMessage {
		$element: &;

		color: map-get($colors, normal);
		margin-top: 2 / 0.65 * 1em;
		font-size: 0.65em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		line-height: 1.4;

		@at-root #{$block}.has-error #{$element} {
			color: map-get($colors, error);
		}
	}

	&__finish {
		@extend %clearfix;
	}

}
