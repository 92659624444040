@mixin right-list($padding) {
	text-align: right;
	padding-left: 0;
	padding-right: $padding;
	&::after {
		left: auto;
		right: 6px;
	}
}

.services {

	&__services {
		@each $size in tablet, desktop {
			@include breakpoint(map-get($breakpoints, $size)) {
				@include with-layout(map-get($grids, $size)) {
					@include container;
				}
			}
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding-top: 10vh;
		}
	}

	&__row {
		@include break();
	}

	&__service {
		&--m-left {
			text-align: left;
		}
		&--m-right {
			text-align: right;
		}
		@include breakpoint(map-get($breakpoints, tablet)) {
			&--m-left,
			&--m-right {
				text-align: inherit;
			}
		}
		&Heading {
			font-family: $dooodleista;
			color: white;
			line-height: 1;
			font-weight: bold;
			font-size: 2.25em;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			@include breakpoint(map-get($breakpoints, tablet)) {
				margin-top: 2em;
			}
		}
		&Option {
			color: $link-color;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-size: 0.8em;
			font-weight: bold;
		}
		p {
			font-size: 0.8em;
			letter-spacing: 0.065em;
			line-height: 1.4;
		}
		ul {
			margin: 1em 0;
			padding: 0;
			list-style-type: none;

			$bullet-padding: 1.5em;

			> li {
				padding-left: $bullet-padding;
				margin: 1em 0;
				font-size: 0.8em;
				letter-spacing: 0.065em;
				line-height: 1.4;
				&::after {
					content: '';
					display: block;
					position: absolute;
					left: 6px;
					top: 6px;
					width: 4px;
					height: 4px;
					border: {
						color: $link-color;
						width: 2px;
						style: solid;
					}
				}
			}

			@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
				&.m-right > li {
					@include right-list($bullet-padding);
				}
			}
			@include breakpoint(map-get($breakpoints, tablet)) {
				&.t-right > li {
					@include right-list($bullet-padding);
				}
			}
			@include breakpoint(map-get($breakpoints, desktop)) {
				> li {
					margin-left: -$bullet-padding;
				}
				&.t-right > li {
					margin-right: -$bullet-padding;
				}
			}

		}
	}

	&__choice {
		@include grid-breakpoint(tablet) {
			text-align: center;
			@include pre(1);
			@include span(8);
		}
		@include grid-breakpoint(desktop) {
			@include pre(2);
			@include span(8);
		}
		p {
			font-size: 0.8em;
			letter-spacing: 0.065em;
			line-height: 1.4;
		}
	}

	&__conclusion {
		text-align: center;
		text-transform: uppercase;
		color: white;
		font-size: 0.7em;
		letter-spacing: 0.05em;
		padding: 2em 0;
	}

	&__cta {
		margin: 0;
		padding: 0;
		text-align: center;
		@include grid-breakpoint(tablet) {
			@include pre(2);
			@include span(6);
			display: flex;
			justify-content: space-around;
			list-style-type: none;
		}
		@include grid-breakpoint(desktop) {
			@include pre(2);
			@include span(8);
		}

		> li {
			display: inline-block;
		}

		a {
			margin: 1em 0;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 0.8em;
			color: white;
			text-decoration: none;
			letter-spacing: 0.1em;
			border-bottom: {
				width: 2px;
				color: $link-color;
				style: solid;
			}
			&:hover {
				color: $link-color;
			}
		}
	}
}
