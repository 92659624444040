%disabledLink {
	opacity: 0.5 !important;
	&, & * {
		cursor: default !important;
		pointer-events: none !important;
		user-select: none !important;
	}
}

.menu {
	$block: &;

	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	pointer-events: none;

	transition-property: filter;

	@include breakpoint(map-get($breakpoints, tablet)) {
		@at-root .gallery-active #{$block} {
			filter: blur(10px) contrast(75%) brightness(75%);
			transition-timing-function: $transition-ease-in;
		}
	}

	&__logo {
		$element: &;

		user-select: none;

		width: 50%;
		margin: 0 auto;
		display: block;

		@include breakpoint(orientation landscape) {
			width: 30%;
		}

		@include breakpoint(map-get($breakpoints, phablet)) {
			width: 40%;
			@include breakpoint(orientation landscape) {
				width: 30%;
			}
		}
		@include breakpoint(map-get($breakpoints, tablet)) {
			width: 21%;
			@include breakpoint(orientation landscape) {
				width: 21%;
			}
		}

		svg {
			display: block;
			width: 100%;
		}

		&Tablet,
		&Mobile {
			pointer-events: auto;
			&.is-collapsed {
				cursor: pointer;
			}
		}
		&Tablet {
			display: none;
			top: 3em;
			@include breakpoint(map-get($breakpoints, tablet)) {
				display: block;
			}
		}
		&Mobile {
			top: 2em;
			@include breakpoint(map-get($breakpoints, tablet)) {
				display: none;
			}
		}
	}

	&__hint {
		$element: &;

		position: absolute;
		text-align: center;
		width: 100%;
		bottom: 18vh;
		text-transform: uppercase;
		font-size: 0.625em;
		letter-spacing: 0.05em;
		opacity: 0.75;

		transition-property: bottom;
		transition-timing-function: $transition-ease-in;

		@at-root {
			.page-vision #{$element},
			.page-workshop #{$element} {
				@include breakpoint(map-get($breakpoints, tablet)) {
					bottom: 28vh;
					@include breakpoint(orientation landscape) {
						bottom: 23vh;
					}
				}
			}
		}
		@at-root .page-workshop.section-first #{$element} {
			@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
				@include breakpoint(orientation landscape) {
					bottom: 5vh;
				}
			}
		}
		@at-root .page-workshop.page-origins.section-first #{$element} {
			@include breakpoint(max-width map-get($breakpoints, tablet) - 1px) {
				@include breakpoint(orientation landscape) {
					bottom: 10.5vh;
				}
			}
		}

		img,
		svg {
			top: 2px;
			margin: 0 0.25em;
		}

		&Desktop {
			display: none;
		}

		@include breakpoint(map-get($breakpoints, desktop)) {
			&Tablet {
				display: none;

			}
			&Desktop {
				display: block;

				img,
				svg {
					top: 21 / 4 * 1px;
				}
			}
		}
	}

	&__main {
		$element: &;

		position: absolute;
		width: 100%;
		bottom: 50vh;
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-family: $dooodleista;
		text-transform: uppercase;
		color: white;
		white-space: nowrap;

		transition-property: bottom;
		transition-timing-function: $transition-ease-in;

		@include breakpoint(orientation landscape) {
			bottom: 40vh;
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			bottom: 38vh;
			@include breakpoint(orientation landscape) {
				bottom: 38vh;
			}
		}

		@at-root {
			.page-vision #{$element},
			.page-workshop #{$element} {
				display: none;

				@include breakpoint(map-get($breakpoints, tablet)) {
					display: block;
					bottom: 21vh;
					@include breakpoint(orientation landscape) {
						bottom: 21vh;
					}
				}
			}
			.page-notfound #{$element} {
				display: none;

				@include breakpoint(map-get($breakpoints, tablet)) {
					display: block;
					bottom: 25vh;
					@include breakpoint(orientation landscape) {
						bottom: 25vh;
					}
				}
			}
		}

		&.is-active a {
			pointer-events: auto;
		}
	}

	&__lineTop {
		$element: &;

		$width: 2px;
		$height: 10vh;
		position: absolute;
		top: 55%;
		left: 50%;
		width: $width;
		height: $height;
		margin-left: -$width/2;
		margin-top: -$height/2;

		transition-property: top, margin-top, height;
		transition-timing-function: $transition-ease-in;

		display: none;

		@include breakpoint(map-get($breakpoints, tablet)) {
			display: block;
			top: 65%;
		}

		@at-root {
			.page-home #{$element} {
				display: block;
				height: 15vh;
				margin-top: -15vh/2;

				@include breakpoint(orientation landscape) {
					top: 65%;
				}
				@include breakpoint(map-get($breakpoints, tablet)) {
					height: 10vh;
					margin-top: -10vh/2;
				}
			}
			.page-vision #{$element},
			.page-workshop #{$element},
			.page-notfound #{$element} {
				@include breakpoint(map-get($breakpoints, tablet)) {
					margin-top: 0;
				}
			}
			.page-vision #{$element} {
				top: 12vh;
				height: 8vh;
			}
			.page-vision.page-1 #{$element} {
				height: 4vh;
			}
			.page-workshop #{$element},
			.page-notfound #{$element} {
				top: 14vh;
				height: 8vh;
			}
		}

		svg {
			width: 100%;
			height: 100%;

			* {
				stroke: $link-color;
				stroke-width: 1px;
				fill: $link-color;
			}
		}
	}

	&__lineMiddle {
		$width: 2px;
		position: absolute;
		height: 0;
		top: 50%;
		left: 50%;
		width: $width;
		margin-left: -$width/2;

		transition-property: top, height;
		transition-timing-function: $transition-ease-in;

		display: none;

		@include breakpoint(map-get($breakpoints, tablet)) {
			display: block;
		}

		svg {
			width: 100%;
			height: 100%;

			* {
				stroke: $link-color;
				stroke-width: 1px;
				fill: $link-color;
			}
		}
	}

	&__lineBottom {
		$element: &;

		$width: 2px;
		$height: 10vh;
		position: absolute;
		bottom: 45%;
		left: 50%;
		width: $width;
		height: $height;
		margin-left: -$width/2;
		margin-bottom: -$height/2;

		transition-property: bottom, margin-bottom, height;
		transition-timing-function: $transition-ease-in;

		display: none;

		@include breakpoint(map-get($breakpoints, tablet)) {
			display: block;
			bottom: 35%;
		}

		@at-root {
			.page-home #{$element} {
				display: block;
				height: 15vh;
				margin-top: -15vh/2;

				@include breakpoint(orientation landscape) {
					bottom: 35%;
				}
				@include breakpoint(map-get($breakpoints, tablet)) {
					height: 10vh;
					margin-top: -10vh/2;
				}
			}
			.page-notfound #{$element} {
				bottom: 21%;
				@include breakpoint(map-get($breakpoints, tablet)) {
					height: 10vh;
					margin-top: -10vh/2;
				}
			}
			.page-vision #{$element},
			.page-workshop #{$element} {
				@include breakpoint(map-get($breakpoints, tablet)) {
					bottom: 0;
					margin-bottom: 0;
				}
				height: 4vh;
			}
			.page-vision #{$element},
			.page-workshop.section-first #{$element} {
				height: 20vh;
			}
			.page-vision.page-4 #{$element},
			.page-workshop.page-origins.section-first #{$element} {
				height: 8vh;
			}
		}

		svg {
			width: 100%;
			height: 100%;

			* {
				stroke: $link-color;
				stroke-width: 1px;
				fill: $link-color;
			}
		}
	}

	&__vision,
	&__workshop {
		position: absolute;
		top: 0;
		padding: {
			top: 1em;
			bottom: 1em;
		}
		letter-spacing: 0.1em;
		display: inline-block;

		a {
			color: white;
			text-decoration: none;
			display: block;

			&:hover h1 {
				color: $link-color;
			}
		}

		h1 {
			font-size: 1.5em;
			line-height: 1;
			margin: 0;
		}

		svg {
			position: absolute;
			top: 0;
			width: auto;
		}
	}
	&__vision {
		padding-right: 30px;
		right: 50%;

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding-right: 70px;
		}

		h1 {
			text-align: right;
			padding: {
				right: 0.3em;
				bottom: 0.5em;
			}
		}

		svg {
			right: 0;
			height: 100%;
		}

		@at-root & {
			a {
				&:hover {
					svg .vision-1 {
						fill: $link-color;
					}
				}
			}
		}

		&.is-disabled {
			@extend %disabledLink;
		}
	}
	&__workshop {
		padding-left: 25px;
		left: 50%;

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding-left: 70px;
		}

		h1 {
			text-align: left;
			padding: {
				left: 1.2em;
			}
		}

		svg {
			left: 0;
			height: 85%;
		}

		@at-root & {
			a {
				&:hover {
					svg {
						.workshop-1 {
							stroke: $link-color;
						}
						.workshop-2 {
							fill: $link-color;
						}
					}
				}
			}
		}

		&.is-disabled {
			@extend %disabledLink;
		}
	}

	&__contact {
		$element: &;

		position: absolute;
		width: 100%;
		bottom: 9vh;
		text-align: center;

		transition-property: bottom;
		transition-timing-function: $transition-ease-in;

		&.is-active {
			pointer-events: auto;
		}

		@include breakpoint(orientation landscape) {
			bottom: 5vh;
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			bottom: 18vh;
			@include breakpoint(orientation landscape) {
				bottom: 18vh;
			}
		}

		@at-root {
			.page-notfound #{$element} {
				display: none;
				@include breakpoint(map-get($breakpoints, tablet)) {
					display: block;
				}
			}
			.page-vision #{$element},
			.page-workshop #{$element} {
				@include breakpoint(map-get($breakpoints, tablet)) {
					bottom: 3vh;
					@include breakpoint(orientation landscape) {
						bottom: 6vh;
					}
				}
			}
			.page-notfound #{$element} {
				@include breakpoint(map-get($breakpoints, tablet)) {
					bottom: 6vh;
				}
			}
		}

		h1 {
			color: $link-color;
			display: inline-block;
			line-height: 1;
			font-size: 0.65em;
			border-bottom: 1px solid $link-color;
			margin: {
				top: 1em;
				right: auto;
				bottom: 1em;
				left: auto;
			}

			a {
				color: white;
				display: inline-block;
				line-height: 1;
				text-decoration: none;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				padding-bottom: 0.1em;

				&:hover, &:focus {
					color: $link-color;
				}
			}
		}

		ul {
			$element: &;

			list-style-type: none;
			width: 4.6em;
			margin: 0 auto;
			padding: 0;

			@at-root {
				.page-vision #{$element},
				.page-workshop #{$element},
				.page-notfound #{$element} {
					display: none;

					@include breakpoint(map-get($breakpoints, tablet)) {
						display: block;
					}
				}
			}
		}

		li {
			display: inline-block;
			width: 50%;

			&:first-child {
				text-align: right;
				padding-right: 0.8em;
			}
			&:last-child {
				text-align: left;
				padding-left: 0.8em;
			}

			&:hover svg * {
				fill: $link-color;
			}
		}
	}

	&__mobileMenuWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include breakpoint(map-get($breakpoints, tablet)) {
			display: none;
		}
	}

	&__gridContainer {
		@include grid-container;
	}

	&__toggleContainer {
		margin-top: 3.4em;
		@include span(1 first);
		@include grid-breakpoint(phablet) {
			@include span(1 first);
		}
	}

	&__toggle {
		$element: &;

		user-select: none;
		-webkit-tap-highlight-color: transparent;

		cursor: pointer;
		width: 5vw;
		min-width: 1.6em;

		&.is-active {
			pointer-events: auto;
		}

		> svg {
			width: 100%;
			height: auto;
		}

		path {
			stroke: white;
			stroke-width: 2px;
		}

		&:hover {
			path {
				stroke: $link-color;
			}
		}
	}

	&__mobileMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $background-color;
		pointer-events: auto;

		display: none;

		&.is-active {
			display: block;
		}

		&Vision,
		&Workshop {
			font-family: $dooodleista;
			text-transform: uppercase;
			white-space: nowrap;
			letter-spacing: 0.1em;
			position: absolute;
			bottom: 25vh;
			left: 0;
			width: 100%;
			padding: {
				top: 1em;
				bottom: 1em;
			}
			letter-spacing: 0.1em;
			text-align: center;

			a {
				color: white;
				text-decoration: none;
				display: inline-block;

				&:hover h1,
				&.is-active h1 {
					color: $link-color;
				}
			}

			h1 {
				font-size: 1.5em;
				line-height: 1;
				margin: 0;
			}

			svg {
				position: absolute;
				top: 0;
				width: auto;
			}
		}
		&Vision {
			h1 {
				text-align: right;
				padding: {
					right: 0.3em;
					bottom: 0.5em;
				}
			}

			@include breakpoint(orientation landscape) {
				bottom: 21vh;
			}

			svg {
				right: 0;
				height: 100%;
			}

			@at-root & {
				a {
					&:hover {
						svg .vision-1 {
							fill: $link-color;
						}
					}
				}
			}

			@at-root .page-vision & {
				display: none;
			}

			@at-root .page-notfound & {
				bottom: 55vh;
			}
		}
		&Workshop {
			h1 {
				text-align: left;
				padding: {
					left: 1.2em;
				}
			}

			svg {
				left: 0;
				height: 85%;
			}

			@at-root & {
				a {
					&:hover {
						svg {
							.workshop-1 {
								stroke: $link-color;
							}
							.workshop-2 {
								fill: $link-color;
							}
						}
					}
				}
			}

			@at-root .page-workshop & {
				display: none;
			}
			@at-root .page-notfound & {
				bottom: 35vh;
			}
		}

		&Contact {
			$element: &;

			position: absolute;
			width: 100%;
			top: 33vh;
			text-align: center;

			@at-root {
				.page-workshop #{$element},
				.page-notfound #{$element} {
					top: 77vh;
				}
			}

			transition-property: top;
			transition-timing-function: $transition-ease-in;

			h1 {
				color: $link-color;
				display: inline-block;
				line-height: 1;
				font-size: 0.65em;
				border-bottom: 1px solid $link-color;
				margin: {
					top: 1em;
					right: auto;
					bottom: 1em;
					left: auto;
				}

				a {
					color: white;
					display: inline-block;
					line-height: 1;
					text-decoration: none;
					text-align: center;
					text-transform: uppercase;
					letter-spacing: 0.1em;
					padding-bottom: 0.1em;

					&:hover, &:focus {
						color: $link-color;
					}
				}

				transition-property: opacity;
				transition-timing-function: $transition-ease-in;
			}
			@at-root .page-workshop & h1 {
				opacity: 0;
				pointer-events: none;
			}

			ul {
				$element: &;

				list-style-type: none;
				width: 4.6em;
				margin: 0 auto;
				padding: 0;
			}

			li {
				display: inline-block;
				width: 50%;

				&:first-child {
					text-align: right;
					padding-right: 0.8em;
				}
				&:last-child {
					text-align: left;
					padding-left: 0.8em;
				}

				&:hover svg * {
					fill: $link-color;
				}
			}
		}

		&WorkshopLinks {
			$element: &;

			position: absolute;
			top: 18vh;
			text-align: center;
			list-style-type: none;
			margin: 0;
			padding: 0;

			@include span(first 6 last);
			@include grid-breakpoint(phablet) {
				@include span(first 6 last);
			}

			transition-property: opacity;
			transition-timing-function: $transition-ease-in;

			@at-root .page-vision & {
				opacity: 0;
				pointer-events: none;
			}
			@at-root .page-notfound & {
				display: none;
			}

			li {
				color: $link-color;
				display: table;
				line-height: 1;
				font-size: 0.65em;
				border-bottom: 1px solid $link-color;
				margin: {
					top: 1em;
					right: auto;
					bottom: 6vh;
					left: auto;
				}

				&:last-child {
					margin-bottom: 0;
				}

				@include breakpoint(orientation landscape) {
					margin-top: 0.5em;
					margin-bottom: 4vh;
				}

				a {
					color: white;
					display: inline-block;
					line-height: 1;
					text-decoration: none;
					text-align: center;
					text-transform: uppercase;
					letter-spacing: 0.1em;
					padding-bottom: 0.1em;

					&:hover, &:focus {
						color: $link-color;
					}
				}
			}
		}
	}
}
