$gallery-grids: (
	mobile: (
		container: calc(100% - #{$grid-mobile-margin * 2}),
		columns: 6,
	),
	phablet: (
		container: calc(100% - #{$grid-mobile-margin * 2}),
		columns: 6,
	),
	tablet: (
		container: calc(100% - #{$grid-mobile-margin * 3}),
		columns: 10,
	),
	desktop: (
		container: calc(100% - #{$grid-mobile-margin * 4}),
		columns: 12,
	),
);

@mixin gallery-grid-breakpoint($size) {
	@if $size == mobile {
		@include with-layout(map-get($gallery-grids, $size)) {
			@content;
		}
	} @else {
		@include breakpoint(map-get($breakpoints, $size)) {
			@include with-layout(map-get($gallery-grids, $size)) {
				@content;
			}
		}
	}
}

@mixin gallery-grid-container {
	@each $size, $grid in $gallery-grids {
		@include gallery-grid-breakpoint($size) {
			@include container;
		}
	}
}

.gallery {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: auto;
	background-color: $background-color;

	@include breakpoint(map-get($breakpoints, tablet)) {
		background-color: transparent;
	}

	&__container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
	}

	&__itemWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: none;

		&.is-active {
			display: block;
		}
	}

	&__gridContainer {
		@include gallery-grid-container;
		padding-top: 3em;
		padding-bottom: 3em;
		height: 100%;
	}

	&__item {
		@include gallery-grid-breakpoint(mobile) {
			@include span(first 6 last);
		}
		@include gallery-grid-breakpoint(phablet) {
			@include span(first 6 last);
		}
		@include gallery-grid-breakpoint(tablet) {
			@include pre(1);
			@include span(8);
		}
		@include gallery-grid-breakpoint(desktop) {
			@include pre(1);
			@include span(10);
		}

		height: 100%;
		overflow-y: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		touch-action: manipulation !important;
		touch-action: pan-y !important;


		figure {
			margin: 0;
			max-width: 100%;
			max-height: 100%;
		}

		img, video {
			border: 0.4em solid black;
			max-width: 100%;
			height: auto;
			display: block;
			user-select: none;
			-webkit-user-drag: none;
		}
		video {
			object-fit: cover;
			position: static;
		}

		figcaption {
			text-align: left;
			font-style: italic;
			font-size: 0.8em;
			letter-spacing: 0.065em;
			margin: {
				top: 0.3em;
				right: 0;
				bottom: 0.5em;
				left: 0;
			}

			line-height: 1.4;
		}
	}

	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4em;
		margin-left: -2em;
		margin-top: -3em;
		cursor: pointer;
		filter: drop-shadow(0 0 3px hsla(0, 0%, 0%, 0.5));

		@at-root .is-playing & {
			display: none;
		}

		* {
			fill: none;
			stroke: white;
			stroke-width: 3px;
		}

		&:hover * {
			stroke: $link-color;
		}
	}

	&__menu {
		@include gallery-grid-container;
		height: 100%;
		pointer-events: none;
	}

	&__close {
		position: absolute;
		top: 2em;
		right: 0;
		cursor: pointer;
		pointer-events: auto;
		width: 1em;

		@include breakpoint(map-get($breakpoints, tablet)) {
			top: 3em;
		}

		svg * {
			stroke: white;
			stroke-width: 2px;
		}

		&:hover svg * {
			stroke: $link-color;;
		}
	}

	&__prevNext {
		list-style-type: none;
		margin: 0;
		padding: 0;
		top: 50vh;
		margin-top: -20px;
	}

	&__prev,
	&__next {
		height: 40px;
		cursor: pointer;
		pointer-events: auto;
		filter: drop-shadow(0 0 3px hsla(0, 0%, 0%, 0.5));

		/* Disable selection, otherwise fast clicking will select some
		   contents */
		user-select: none;

		display: none;

		@include breakpoint(map-get($breakpoints, tablet)) {
			display: block;
		}

		svg {
			overflow: visible;
			position: absolute;
			top: 0;
			height: 100%;
			width: auto;
		}
	}
	&__prev {
		@include gallery-grid-breakpoint(mobile) {
			@include span(1 first);
		}
		@include gallery-grid-breakpoint(phablet) {
			@include span(1 first);
		}
		@include gallery-grid-breakpoint(tablet) {
			@include span(1 first);
		}
		@include gallery-grid-breakpoint(desktop) {
			@include span(1 first);
		}

		svg {
			left: 0;
		}
	}
	&__next {
		@include gallery-grid-breakpoint(mobile) {
			@include span(1 last);
			@include pre(4);
		}
		@include gallery-grid-breakpoint(phablet) {
			@include span(1 last);
			@include pre(4);
		}
		@include gallery-grid-breakpoint(tablet) {
			@include span(1 last);
			@include pre(8);
		}
		@include gallery-grid-breakpoint(desktop) {
			@include span(1 last);
			@include pre(10);
		}

		svg {
			right: 0;
		}
	}
}
