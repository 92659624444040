.work {

	&__heading {
		font-family: $dooodleista;
		font-weight: bold;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		font-size: 2.25em;
	}

	&__subHeading {
		letter-spacing: 0.1em;
		text-transform: uppercase;
		font-size: 0.8em;
		text-align: center;
		font-weight: bold;
	}
}
