.not-found {
	overflow-y: auto;

	.workshop__sectionContainer {
		$span: span(6);
		$container: container();
		$padding: calc(calc(100% - calc(#{$span/100%} * #{$container})) / 2);
		position: absolute;
		top: 10vh;
		right: $padding;
		bottom: 15vh;
		left: $padding;
		overflow-y: visible;

		@include breakpoint(orientation landscape) {
			top: 15vh;
			bottom: 20vh;
		}

		@include grid-breakpoint(phablet) {
			$span: span(4);
			$container: container();
			$width: $span/100% * $container;
			$padding: calc(calc(100% - #{$width}) / 2);
			right: $padding;
			left: $padding;
		}

		@include breakpoint(map-get($breakpoints, tablet)) {
			padding: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	.workshop__background {
		position: fixed;
		object-position: 50% 50%;
	}
	.workshop__subTitleWrapper,
	.workshop__title {
		top: -10%;
	}

	&__form {
		margin-top: 50vh;
		margin-bottom: 1em;

		&Submit label {
			display: none;
		}

		@include grid-breakpoint(tablet) {
			margin: 0;
			top: 43%;

			&Search {
				@include pre(2);
				@include span(3);
			}

			&Submit {
				@include span(1);

				button {
					width: 100%;
					margin-top: 0.6em;
					padding: 0.55em;
				}

				label {
					display: block;
				}
			}
		}
		@include grid-breakpoint(desktop) {
			&Search {
				@include pre(4);
				@include span(3);
			}
			&Submit {
				@include span(1);
			}
		}
	}
}
